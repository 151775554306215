import React from "react";
import '../static/css/home.css';
import '../static/css/button.css';
import '../static/css/navBar.css';

// @ts-ignore
import {Button, Card, Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import BreastFeeding from "./breastFeeding/breastFeeding";
import ModHandling from "./modHandling/modHandling";
import BreastFeedingWorkshop from "./breastFeedingWorkshop/breastFeedingWorkshop";
import Babyfood from "./babyFood/babyfood";
import BRAND_LOGO from "../static/images/logos/Karin Logo_Webversion_72dpi.png";
import Timeline from "./aboutMe/timeLine/timeline";
import Contact from "./contact/contact";
import MyCourses from "./myCourses/myCourses";


const Home = () => {
    return (
        <div>
            <div className={'section section-heading align-content-end'}>
                <Container>
                    <div className="logo-brand-container">
                        <img alt="" src={BRAND_LOGO} className={"logo-hero"}/>
                        <p className={'logo-brand-name'}>KS - Stillberatung</p>
                    </div>
                    <div className={'section-heading-intro'}>
                    <Row>
                        <Col className={''}>
                            <p>
                                Stillen ist eine wundervolle und natürliche Art, ihr Baby zu
                                ernähren. Doch manchmal können dabei Fragen und
                                Herausforderungen auftreten.
                            </p>
                            <p>
                                Ich bin hier, um Sie mit fachkundiger Beratung und
                                einfühlsamer Unterstützung auf Ihrem Weg zu begleiten.
                                Mein Ziel ist es, Ihnen und Ihrem Baby zu einem
                                harmonischen und erfolgreichen Stillen zu verhelfen.
                            </p>
                            <p>
                                Vertrauen Sie auf meine Erfahrung und Expertise  für einen guten Start ins gemeinsame Leben!
                            </p>
                        </Col>
                    </Row>
                    </div>
                </Container>
            </div>
            <MyCourses additionalClass={''}/>
            <Timeline />
            <BreastFeeding additionalClass={''}/>
            <ModHandling additionalClass={''}/>
            <BreastFeedingWorkshop additionalClass={''}/>
            <Babyfood additionalClass={''}/>
            <Contact additionalClass={''}/>
            {/*<div className={'section section-services'}>*/}
            {/*    <Container>*/}
            {/*        <Row>*/}
            {/*            <Col xs={12} lg={4} className="d-flex">*/}
            {/*                <Card className="flex-fill d-flex flex-column">*/}
            {/*                    <Card.Img variant="top" src={breastfeedingSupport} className="card-img"/>*/}
            {/*                    <Card.Body className="d-flex flex-column">*/}
            {/*                        <Card.Title>Stillberatung</Card.Title>*/}
            {/*                        <Card.Text className="flex-grow-1">*/}
            {/*                            Schritt für Schritt durch die Stillzeit. Ich berate Sie zu allen Fragen rund um*/}
            {/*                            das Thema Stillen, von Beginn der Schwangerschaft bis zum Wiedereinstieg ins*/}
            {/*                            Berufsleben.*/}
            {/*                        </Card.Text>*/}
            {/*                        <Button className='button-custom' href={"/breastFeeding"}>Stillberatung</Button>*/}
            {/*                    </Card.Body>*/}
            {/*                </Card>*/}
            {/*            </Col>*/}

            {/*            <Col xs={12} lg={4} className="d-flex mt-3 mt-lg-0">*/}
            {/*                <Card className="flex-fill d-flex flex-column">*/}
            {/*                    <Card.Img variant="top" src={modhandling} className="card-img"/>*/}
            {/*                    <Card.Body className="d-flex flex-column">*/}
            {/*                        <Card.Title>Mod-Kurs <br></br>(babyhandligskurs)</Card.Title>*/}
            {/*                        <Card.Text className="flex-grow-1">*/}
            {/*                            Berührungen, die Halt geben. Fördern Sie die Gesundheit sowie die Entwicklung*/}
            {/*                            Ihres Kindes und festigen Sie die Eltern-Kind-Beziehung mit einer liebevollen*/}
            {/*                            Massage.*/}
            {/*                        </Card.Text>*/}
            {/*                        <Button className='button-custom' href={"/modhandling"}>Mod-Kurs</Button>*/}
            {/*                    </Card.Body>*/}
            {/*                </Card>*/}
            {/*            </Col>*/}

            {/*            <Col xs={12} lg={4} className="d-flex mt-3 mt-lg-0">*/}
            {/*                <Card className="flex-fill d-flex flex-column">*/}
            {/*                    <Card.Img variant="top" src={breastfeedingCourse} className="card-img"/>*/}
            {/*                    <Card.Body className="d-flex flex-column">*/}
            {/*                        <Card.Title>Still-Workshop <br></br>(Stillvorbereitungskurs)</Card.Title>*/}
            {/*                        <Card.Text className="flex-grow-1">*/}
            {/*                            Buchen Sie jetzt Ihren privaten Beratungstermin. Mein vielseitiges Kursangebot*/}
            {/*                            informiert und begleitet Sie durch die Schwangerschaft und die gesamte*/}
            {/*                            Elternzeit.*/}
            {/*                        </Card.Text>*/}
            {/*                        <Button className='button-custom' href={"/breastFeedingWorkshop"}>Workshop</Button>*/}
            {/*                    </Card.Body>*/}
            {/*                </Card>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Row className="align-items-center">*/}
            {/*            <Col xs={12} lg={{span: 4, offset: 4}} className="d-flex mt-3 mt-lg-3">*/}
            {/*                <Card className="flex-fill d-flex flex-column">*/}
            {/*                    <Card.Img variant="top" src={supportFood} className="card-img"/>*/}
            {/*                    <Card.Body className="d-flex flex-column">*/}
            {/*                        <Card.Title className={'card-title'}>Beikost</Card.Title>*/}
            {/*                        <Card.Text className="flex-grow-1">*/}
            {/*                            Buchen Sie jetzt Ihren privaten Beratungstermin. Mein vielseitiges Kursangebot*/}
            {/*                            informiert und begleitet Sie durch die Schwangerschaft und die gesamte*/}
            {/*                            Elternzeit.*/}
            {/*                        </Card.Text>*/}
            {/*                        <Button className='button-custom' href={"/babyfood"}>Beikost</Button>*/}
            {/*                    </Card.Body>*/}
            {/*                </Card>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}
        </div>
    );
}

export default Home;