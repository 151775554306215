import React from "react";
import '../../static/css/myCourses.css';
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import courseImage from '../../static/images/courses/2025-02-06-BeikostWorkshop.jpg'; // Import your image
import {motion} from 'framer-motion'; // Import Framer Motion

interface MyCoursesProps {
    additionalClass?: string; // optional prop of type string
}

const MyCourses: React.FC<MyCoursesProps> = ({ additionalClass }) => {
    return (
        <div id={'myCourses'}>
            <div className={`section section-myCourses ${additionalClass ? additionalClass : ''}`}>
                <Container>
                    <p className={'section-header-title'}>Aktuelle Workshops</p>
                    <Row className={'align-item-center'}>
                        <Col xs={12} className="d-flex justify-content-center">
                            <motion.img
                                src={courseImage}
                                alt="Workshop"
                                className="myCourses-image"
                                initial={{ opacity: 0, scale: 0.8 }} // Initial state
                                whileInView={{ opacity: 1, scale: 1 }} // Animation state
                                transition={{ duration: 0.5 }} // Transition duration
                                viewport={{ once: true, amount: 0.2 }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default MyCourses;
